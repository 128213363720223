import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Card, Col, Container, Table, Row } from 'react-bootstrap';
import { faSearch, faSpinner, faPlus, faCheck, faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import BrokerService from 'shared/broker-service';

import Pagination from 'components/pagination';
import Button from 'components/button';

export default function Brokers() {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [brokers, setBrokers] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
  });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminList({ page, perPage, filters })
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          setBrokers(responseData.message.data);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [page, perPage, dispatch]);

  const submit = (e) => {
    e.preventDefault();
    setBrokers([]);
    setPage(1);
    dispatch({ type: 'SET_LOADING', loading: true });
    BrokerService.adminList({ page: 1, perPage, filters })
      .then((responseData) => {
        if (responseData.status === 'Accepted') {
          setBrokers(responseData.message.data);
          setLastPage(responseData.message.last_page);
        }
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  /* PAGINATOR */
  const paginate = (e) => setPage(e.target.value);

  const handleChangeNumberOfColum = (e) => {
    setPerPage(e.target.value);
    setPage(1);
  };

  const nextPage = (_page) => {
    _page++;
    _page <= lastPage && setPage(_page);
  };

  const prevPage = (_page) => {
    _page--;
    _page > 0 && setPage(_page);
  };
  /* End Pagination*/

  return (
    <Container className={`mw-100`}>
      <Row>
        <Col>
          <Card className="mb-2">
            <Card.Header>Brokers / Black Label</Card.Header>
            <Card.Body>
              <Row className="mb-4">
                <Col>
                  <Form submit={submit} filters={filters} onChange={onChange} />
                </Col>
                <Col className="text-right align-self-center">
                  <Button blue rounded className="mb-2" onClick={() => history.push('/admintools/brokers/nuevo')}>
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    <FormattedMessage id="global-new" />
                  </Button>
                  <br />
                </Col>
              </Row>
              {!loading && (
                <>
                  <hr />
                  <Row>
                    <Col sm="12">{brokers && <DataTable data={brokers} />}</Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Pagination
                        handleChangeNumberOfColum={handleChangeNumberOfColum}
                        pager={{ page: page, items: perPage, pages: lastPage }}
                        paginate={paginate}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        total={brokers.total}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const Form = ({ submit, filters, onChange }) => {
  let loading = useSelector((state) => state.loading.loading);

  return (
    <form onSubmit={submit}>
      <div className="first-filters d-flex align-center">
        <div className="form-group mr-3">
          <label>
            <FormattedMessage id="global-search" />
            <input className="form-control" name="search" value={filters.search} onChange={onChange} />
          </label>
        </div>
      </div>
      <div className="buttons-content">
        <Button rounded type="submit" disabled={loading}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
          ) : (
            <>
              <FontAwesomeIcon icon={faSearch} className="mr-2" /> <FormattedMessage id="global-send" />
            </>
          )}
        </Button>
      </div>
    </form>
  );
};

const DataTable = ({ data }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const resetRequests = (id) => {
    if (window.confirm('¿Estás seguro que quieres resetear las peticiones?')) {
      dispatch({ type: 'SET_LOADING', loading: true });
      BrokerService.adminResetRequests(id)
        .then((response) => {
          if (response.status === 'Failed') toast.error('No se pudo resetear las peticiones');
          else toast.success('Peticiones reseteadas');
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  return (
    <Table responsive="sm" striped bordered hover className="text-center">
      <thead>
        <tr>
          <th>ID</th>
          <th>
            <FormattedMessage id="global-name" />
          </th>
          <th>Email</th>
          <th>
            <FormattedMessage id="global-user" />
          </th>
          <th>Status</th>
          <th>Audio</th>
          <th>Toy</th>
          <th>Credits Spy</th>
          <th>Credits Group</th>
          <th>Credits Vip</th>
          <th>Credits Audio</th>
          <th>Resetear peticiones</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.BR}>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.BR}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.nombre}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.email}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.usuario}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                <Status status={item.status} />
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.audio === 1 && <FontAwesomeIcon icon={faCheck} />}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.toy === 1 && <FontAwesomeIcon icon={faCheck} />}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.credits_spy}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.credits_group}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.credits_vip}
              </td>
              <td onClick={() => push('/admintools/brokers/' + item.BR)} className="cursorPointer">
                {item.credits_audio}
              </td>
              <td>
                <Button blue onClick={(e) => resetRequests(item.BR)}>
                  <FontAwesomeIcon icon={faServer} className="mr-2" />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const Status = ({ status }) => {
  return {
    0: 'SuperAdmin',
    2: 'Usuario',
    99: 'No validado',
    666: 'Baneado',
    777: 'Baja',
  }[status];
};
